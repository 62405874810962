import React, { useEffect, useState } from 'react';
import './EnsinoFundamental1.css';

import Header from '../../../../componentes/js/Header';
import Footer from '../../../../componentes/js/Footer';
import Modal from '../../../../componentes/js/Modal';
import LivrosCarousel from '../../../../componentes/js/LivrosCarrossel';

function EnsinoFundamental1() {
  const [livros, setLivros] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const timestamp = new Date().getTime();

  useEffect(() => {
    fetch('https://server-colegiolider.onrender.com/api/livros/fundamental-1')
      .then(response => {
        if (!response.ok) {
          console.error('Erro ao buscar dados:', response.statusText);
          throw new Error('Erro ao buscar dados');
        }
        return response.json();
      })
      .then(data => {
        console.log('Dados recebidos:', data);
        setLivros(data);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);  

  const cardContents = {
    'Educacao': {
      title: 'Educação Financeira e Empreededorismo',
      text: 'Em conjunto, a educação financeira e o empreendedorismo formam uma base robusta que capacita nossos jovens a serem líderes em suas comunidades, preparados para enfrentar os desafios do mundo moderno com confiança e competência. Em nosso colégio, estamos comprometidos em equipá-los com essas ferramentas essenciais para que possam construir um futuro próspero e sustentável.',
      imageUrl: 'assets/images/educacao.jpeg',
    },
    'CNA': {
      title: 'CNA Inglês',
      text: 'Aqui, nós apoiamos o seu aprendizado de forma leve, divertida e personalizada. Você conversa com o mundo enquanto nós imaginamos um futuro cheio de possibilidades junto com você. No CNA, você pode acreditar!',
      videoUrl: 'https://www.youtube.com/embed/EF2CTjsamiI?vq=hd1080',
    },
    'SEE': {
      title: 'SEE',
      text: 'O Programa de Socioemocional e Ética (PSEE) é o processo pelo qual crianças, jovens e adultos adquirem e aplicam o conhecimento, as habilidades e atitudes para desenvolverem identidades  saudáveis, gerenciar emoções, alcançar metas pessoais e coletivas, sentir e demonstrar empatia pelos outros, estabelecer e manter relações solidárias bem como tomar decisões responsáveis, cuidadosas e orientadas por valores éticos.',
      imageUrl: 'assets/images/see.png',
    }
  };

  return (
    <div>
      <Header />
      <div className="escola">
        <header>
          <div className='tituloEscola'><h2 style={{ fontSize: 40 + 'px'}}>Ensino Fundamental I</h2></div>
          <p>Sistema de Ensino</p>
          <div className='sistemaContainer'>
            <a href='https://www.plurall.net/' rel='noreferrer' target='_blank'>
              <div className='itensSistema'>
                <img src='assets/images/sistemas/plurall.png' alt='plurall'/>
                PLURALL
              </div>
            </a>
          </div>
        </header>
        <section className="ensino">
          <p>
          No Ensino Fundamental I, o ensino é projetado para ser envolvente e relevante para as 
          crianças. Utilizando atividades que capturam a imaginação dos alunos, a educação nessa 
          fase é fortemente conectada às suas experiências diárias. É nesse período que os alunos 
          desenvolvem habilidades essenciais como leitura, escrita e matemática de maneira concreta.
           A educação nesse nível também enfatiza a importância da independência, do comportamento ético
            e da colaboração. Com abordagens que estimulam tanto a curiosidade quanto a expressão individual, 
            os estudantes não apenas adquirem conhecimentos fundamentais, mas também cultivam uma base sólida 
            para o aprendizado contínuo e o sucesso futuro na escola.
          </p>
        </section>
        <section className="atividades">
          <h2>Projetos e Iniciativas</h2>
          <div className="cartas">
            <div className="carta" onClick={() => setActiveCard('Educacao')}>
              <h3>Educação Financeira e Empreendedorismo</h3>
            </div>
            <div className="carta" onClick={() => setActiveCard('CNA')}>
              <h3>CNA Inglês</h3>
            </div>
            <div className="carta" onClick={() => setActiveCard('SEE')}>
              <h3>SEE</h3>
            </div>
          </div>
        </section>

        <section className="calendario">
          <h2>Calendário</h2>
          <div className='imagens-calend'>
            <img src={`assets/images/calendarios/fund1a.png?time=${timestamp}`} alt='funda'></img>
            <img src={`assets/images/calendarios/fund1b.png?time=${timestamp}`} alt='fundb'></img>
          </div>
        </section>

        <Modal
          show={!!activeCard}
          onClose={() => setActiveCard(null)}
          title={cardContents[activeCard]?.title}
          text={cardContents[activeCard]?.text}
          imageUrl={cardContents[activeCard]?.imageUrl}
          videoUrl={cardContents[activeCard]?.videoUrl}
        />

        <section className="livros">
          <h2>Livros</h2>
          <LivrosCarousel livros={livros}/>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default EnsinoFundamental1;