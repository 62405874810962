import React, { useEffect, useState } from 'react';
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';
import './Equipe.css';

const Equipe = () => {
  const [professoresArray, setProfessoresArray] = useState([]);
  const [coordenadoresArray, setCoordenadoresArray] = useState([]);
  const [auxiliaresArray, setAuxiliaresArray] = useState([]);
  const [allImagesArray, setAllImagesArray] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  // Função para carregar imagens em pequenos lotes sem duplicação
  const loadImagesInBatches = (data, setImageArray, batchSize = 3, delay = 250) => {
    let index = 0;

    const loadBatch = () => {
      const batch = data.slice(index, index + batchSize);

      // Atualiza o estado com o novo lote, evitando duplicação
      setImageArray(prevArray => {
        const updatedArray = [...prevArray, ...batch];
        return Array.from(new Set(updatedArray.map(img => img.id))).map(id => updatedArray.find(img => img.id === id));
      });

      index += batchSize;
      if (index < data.length) {
        setTimeout(loadBatch, delay); // Carrega o próximo lote após o delay
      }
    };

    loadBatch();
  };

  const fetchImages = async (url, setImageArray) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Erro ao buscar dados');
      }
      const data = await response.json();
      const reversedData = data.reverse();

      // Carregamento em pequenos lotes
      loadImagesInBatches(reversedData, setImageArray);

      // Pré-carregamento das imagens
      reversedData.forEach(image => {
        const img = new Image();
        img.src = `https://drive.google.com/thumbnail?id=${image.id}&sz=w1080`;
      });
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  useEffect(() => {
    fetchImages('https://server-colegiolider.onrender.com/api/imagens/coordenadores', setCoordenadoresArray);
    fetchImages('https://server-colegiolider.onrender.com/api/imagens/professores', setProfessoresArray);
    fetchImages('https://server-colegiolider.onrender.com/api/imagens/auxiliares', setAuxiliaresArray);
  }, []);

  // Unifica todas as imagens em um único array combinado quando os arrays individuais mudam
  useEffect(() => {
    const combinedArray = [
      ...coordenadoresArray.map(img => ({ ...img, type: 'Coordenadores' })),
      ...professoresArray.map(img => ({ ...img, type: 'Professores' })),
      ...auxiliaresArray.map(img => ({ ...img, type: 'Auxiliares' }))
    ];
    setAllImagesArray(combinedArray);
  }, [coordenadoresArray, professoresArray, auxiliaresArray]);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setSelectedImage(`https://drive.google.com/thumbnail?id=${allImagesArray[index].id}&sz=w1080`);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    setCurrentIndex(null);
  };

  const goToPreviousImage = () => {
    if (allImagesArray.length > 0) {
      const newIndex = (currentIndex - 1 + allImagesArray.length) % allImagesArray.length;
      setCurrentIndex(newIndex);
      setSelectedImage(`https://drive.google.com/thumbnail?id=${allImagesArray[newIndex].id}&sz=w1080`);
    }
  };

  const goToNextImage = () => {
    if (allImagesArray.length > 0) {
      const newIndex = (currentIndex + 1) % allImagesArray.length;
      setCurrentIndex(newIndex);
      setSelectedImage(`https://drive.google.com/thumbnail?id=${allImagesArray[newIndex].id}&sz=w1080`);
    }
  };

  const renderTeamSection = (title, teamArray) => (
    <div className="team-section">
      <h2>{title}</h2>
      <div className="team-grid">
        {teamArray.map((image, index) => (
          <div key={index} className="team-member" onClick={() => openLightbox(allImagesArray.findIndex(img => img.id === image.id))}>
            <img src={`https://drive.google.com/thumbnail?id=${image.id}&sz=w1080`} alt={`${title} ${index + 1}`} className="professor-image" />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <Header />
      {renderTeamSection('Coordenadores', coordenadoresArray)}
      {renderTeamSection('Professores', professoresArray)}
      {renderTeamSection('Auxiliares', auxiliaresArray)}

      {selectedImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <button className="close-button" onClick={closeLightbox}>X</button>
          <button className="prev-button" onClick={(e) => { e.stopPropagation(); goToPreviousImage(); }}>
            ← <span className="button-text">Anterior</span>
          </button>

          <div className="category-label">
            {allImagesArray[currentIndex].type}
          </div>

          <img 
            src={selectedImage} 
            alt="Professor" 
            className="lightbox-image" 
            onClick={(e) => e.stopPropagation()} 
          />

          <button className="next-button" onClick={(e) => { e.stopPropagation(); goToNextImage(); }}>
            <span className="button-text">Próximo</span> →
          </button>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Equipe;