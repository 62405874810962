// src/components/EnsinoFundamental.js
import React from 'react';
import { useEffect, useState } from 'react';
import './EnsinoFundamental2.css';

import Header from '../../../../componentes/js/Header';
import Footer from '../../../../componentes/js/Footer';
import Modal from '../../../../componentes/js/Modal';
import LivrosCarousel from '../../../../componentes/js/LivrosCarrossel';

function EnsinoFundamental2() {
  const [activeCard, setActiveCard] = useState(null);
  const [livros, setLivros] = useState([]);
  const timestamp = new Date().getTime();

  useEffect(() => {
    fetch('https://server-colegiolider.onrender.com/api/livros/fundamental-2')
      .then(response => {
        if (!response.ok) {
          console.error('Erro ao buscar dados:', response.statusText);
          throw new Error('Erro ao buscar dados');
        }
        return response.json();
      })
      .then(data => {
        console.log('Dados recebidos:', data);
        setLivros(data);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);  

  const cardContents = {
    'Jogos': {
      title: 'Jogos Internos',
      text: 'Os jogos internos promovem o trabalho em equipe e a colaboração. Ao participar de atividades esportivas e desafios em grupo, os alunos aprendem a importância de trabalhar juntos para alcançar objetivos comuns. Essas experiências ajudam a fortalecer habilidades de comunicação, liderança e resolução de problemas, que são cruciais tanto no ambiente acadêmico quanto no mercado de trabalho.',
      imageUrl: 'assets/images/jogos-internos.png',
    },
    'Aulas': {
      title: 'Aulas Práticas',
      text: 'Essencialmente, as aulas práticas funcionam como um laboratório que prepara os alunos para o mundo real. Elas estimulam a curiosidade, a criatividade e a capacidade de resolver problemas complexos, habilidades valiosas para qualquer carreira. Integrar essas práticas ao currículo garante que nossos jovens estejam prontos para contribuir de maneira significativa e responsável em qualquer área que escolherem.',
      imageUrl: 'assets/images/cerebro-maker.jpg',
    },
    'CNA': {
      title: 'CNA Inglês',
      text: 'Aqui, nós apoiamos o seu aprendizado de forma leve, divertida e personalizada. Você conversa com o mundo enquanto nós imaginamos um futuro cheio de possibilidades junto com você. No CNA, você pode acreditar!',
      videoUrl: 'https://www.youtube.com/embed/EF2CTjsamiI?vq=hd1080',
    },
    'SEE': {
      title: 'SEE',
      text: 'O Programa de Socioemocional e Ética (PSEE) é o processo pelo qual crianças, jovens e adultos adquirem e aplicam o conhecimento, as habilidades e atitudes para desenvolverem identidades  saudáveis, gerenciar emoções, alcançar metas pessoais e coletivas, sentir e demonstrar empatia pelos outros, estabelecer e manter relações solidárias bem como tomar decisões responsáveis, cuidadosas e orientadas por valores éticos.',
      imageUrl: 'assets/images/see.png',
    }
  };

  return (
    <div>
        <Header />
    <div className="escola">
      <header>
      <div className='tituloEscola'><h2 style={{ fontSize: 40 + 'px'}}>Ensino Fundamental II</h2></div>
        <p>Sistema de Ensino</p>
          <div className='sistemaContainer'>
            <a href='https://www.plurall.net/' rel='noreferrer' target='_blank'>
              <div className='itensSistema'>
                <img src='assets/images/sistemas/plurall.png' alt='plurall'/>
                PLURALL
              </div>
            </a>
          </div>
      </header>

      <section className="ensino">
        <p>
          O Ensino Fundamental II, abrangendo do 6º ao 9º ano, é projetado com um olhar atento 
          às particularidades da adolescência. Esse período educativo continua a fomentar a 
          autonomia intelectual e moral dos jovens, incentivando o uso de diversas formas de 
          linguagem para expressar suas ideias. Enfatiza o desenvolvimento do pensamento lógico, 
          da criatividade e da análise crítica. Reconhecendo que essa fase é marcada por profundas 
          transformações físicas, psicológicas e emocionais, nossa abordagem é cuidadosamente adaptada 
          às necessidades específicas de cada faixa etária. Isso inclui desde a atenção especial às 
          demandas de adaptação dos alunos do 6º ano até o suporte necessário para que os alunos do 9º ano 
          para o Ensino Médio. Além disso, incentivamos a participação ativa em projetos interdisciplinares 
          e atividades extracurriculares, promovendo um aprendizado mais dinâmico e integral.
        </p>
      </section>

      <section className="atividades">
        <h2>Projetos e Iniciativas</h2>
        <div className="cartas">
          <div className="carta" onClick={() => setActiveCard('Jogos')}>
            <h3>Jogos Internos</h3>
          </div>
          <div className="carta" onClick={() => setActiveCard('Aulas')}>
            <h3>Aulas Práticas</h3>
          </div>
          <div className="carta" onClick={() => setActiveCard('CNA')}>
            <h3>CNA Inglês</h3>
          </div>
          <div className="carta" onClick={() => setActiveCard('SEE')}>
            <h3>SEE</h3>
          </div>
        </div>
      </section>

      <section className="calendario">
        <h2>Calendário</h2>
        <img src={`assets/images/calendarios/fund2.png?time=${timestamp}`}></img>
      </section>

      <Modal
          show={!!activeCard}
          onClose={() => setActiveCard(null)}
          title={cardContents[activeCard]?.title}
          text={cardContents[activeCard]?.text}
          imageUrl={cardContents[activeCard]?.imageUrl}
          videoUrl={cardContents[activeCard]?.videoUrl}
      />

      <section className="livros">
          <h2>Livros</h2>
          <LivrosCarousel livros={livros}/>
      </section>
    </div>
    <Footer />
    </div>
  );
}

export default EnsinoFundamental2;
